/* eslint-disable */


import { useState, useEffect, useRef, useCallback } from 'react';
import './App.css';

import { setInitialState, updateData, setRequestAction, resetData } from './features/websocketData/websocketDataSlice';
import { setPlayersInitialState, updatePlayersData } from './features/onlinePlayers/onlinePlayersSlice';
import { addCombatRound, resetCombatData } from './features/combatRounds/combatDataSlice';
import { setCollection } from "./features/playerCollection/playerCollectionSlice";
import YouTubeBackground from './components/YouTubeBackground';
import { useSelector, useDispatch } from 'react-redux';
import Cookies from 'js-cookie'
import Footer from "./components/New/Footer.jsx";
import NavBar from "./components/NavBar";
import MobileNavBar from "./components/MobileNavbar";
import WalletSelector from "./components/WalletSelector";
import axios from "axios";
import { Routes, Route, useLocation, useNavigate } from 'react-router-dom';

import { fetchCollection } from "./utils/fetchData";

import InternalWallet from "./pages/Wallet.jsx";
import Battle from "./pages/Battle.jsx";
import Home from "./pages/Home";
import Replay from "./pages/Replay";
import Collection from "./pages/Collection"
import LoadingOverlay from "./components/Overlay.jsx"
import CardDetails from "./pages/CardDetails.jsx";
import OpenPacks from "./pages/OpenPacks.jsx";
import Game from "./pages/Game.jsx";
import Referral from "./pages/Referral.jsx";
import Unsubscribe from "./pages/Unsubscribe.jsx";
import Test from "./pages/Test.jsx";
import MatchmakingAlert from "./components/MatchmakingAlert";
import Chat from "./components/Chat"
import Maintenance from "./components/maintenance/index.jsx";
import WarmupSeasonDisclaimer from "./components/seasonDisclaimer/WarmupSeasonDisclaimer.jsx";
import PackAlert from "./components/PackAlert";

import Packs from "./pages/Packs.jsx";
import Shop from "./pages/Shop.jsx";

// Hooks
import useWebSocket from './components/hooks/useWebSocketHandler';
import { useGameData } from './components/hooks/useGameData';
import useLogin from './components/hooks/useLogin';

import { useDisconnect } from 'wagmi'
// import { useWalletInfo } from '@reown/appkit/react'
// import Dialog from "./components/Dialog"
import Matchend from "./components/New/Matchend";
// import { ThemeProvider } from '@mui/material/styles';
import Theme from "./components/Theme"
import { wait } from "./utils"
import PlayerModal from "./components/Menu/PlayerModal.jsx";
import ChallengeSnackbar from "./components/Menu/ChallengeSnackbar.jsx";
import { godzillaAudio } from "./audio.js";
import { useMediaQuery } from '@mui/material';
import { getNetwork, getContracts, getProvider, nodeEnv, cdnUrl, dbApiUrl, wsUrl, MONTH, getUnits, getAbilities, getLevels, getConfig, getRewards, getLevelCap, getLore } from './constants';

function App() {
  const isSmallScreen = useMediaQuery(() => Theme.breakpoints.down('md'));
  const [address, setAddress] = useState(null);
  const [wallet, setWallet] = useState(null);
  const [bgImageUrl, setBgImageUrl] = useState('');
  const [payload, setPayload] = useState(null);
  const [showBoard, setShowBoard] = useState(false);
  const [playerModal, setPlayerModal] = useState(false);
  const [challengeRequest, setChallengeRequest] = useState(null);
  const [openWalletSelector, setOpenWalletSelector] = useState(false);
  const [gameStart, setGameStart] = useState(false);
  const [waitingForOpponent, setWaitingForOpponent] = useState(false);
  const [showCivs, setShowCivs] = useState(false);
  const [firstToAct, setFirstToAct] = useState(false);
  const [players, setPlayers] = useState(null);
  const [colors, setColors] = useState(null);
  const [isLoggedIn, setLoggedIn] = useState(false);

  const [isReplay, setIsReplay] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [ranking, setRanking] = useState(null);
  const [rewards, setRewards] = useState(null);
  const [referralCode, setReferralCode] = useState(null);
  const [referrer, setReferrer] = useState(null);
  const [route, setRoute] = useState(null);
  const [faithPrice, setFaithPrice] = useState(null);
  const [packPrice, setPackPrice] = useState(null);

  const dispatch = useDispatch();
  const location = useLocation(); // Hook to get the current location
  const { sendMessage, readyState, messageQueue, setMessageQueue } = useWebSocket(wsUrl);

  const [accountAndFiltersLoaded, setAccountAndFiltersLoaded] = useState(false);
  const [dependenciesLoaded, setDependenciesLoaded] = useState(false);
  const [units, setUnits] = useState([]);
  const [levels, setLevels] = useState([]);
  const [abilities, setAbilities] = useState([]);
  const [levelCap, setLevelCap] = useState(null);
  const [lore, setLore] = useState(null);
  const [config, setConfig] = useState(false);
  const [isGameOver, setGameOver] = useState(false);
  const [nicknameModal, setNicknameModal] = useState(false);
  const [matchmakingAlert, setMatchmakingAlert] = useState(false);
  const [refreshRanking, setRefreshRanking] = useState(false);
  const [combatBusy, setCombatBusy] = useState(false)

  const manaParticleContainerRef = useRef();
  const manaParticleContainerRef2 = useRef();
  const effectContainerRef = useRef();

  const [koinContract, setKoinContract] = useState(null);
  const [packsContract, setPacksContract] = useState(null);
  const [cardsContract, setCardsContract] = useState(null);
  const [faithContract, setFaithContract] = useState(null);
  const [network, setNetwork] = useState(null)
  const [provider, setProvider] = useState(null)
  const [koinBalance, setKoinBalance] = useState(0)
  const [canBuyPacks, setCanBuyPacks] = useState(0)
  const [showCanBuyPacks, setShowCanBuyPacks] = useState(null)

  const koinDecimals = 10**8;

  // Use the custom hook to fetch and manage game data
  const { 
    isLoading, 
    accountData, 
    history, 
    setRefreshCollection, 
    setIsLoading,
    setAccountData,
    setRefreshAccountData,
    setRefreshPrices,
    faithBalance,
    setRefreshBalances
  } = useGameData(address, wallet, setRanking, rewards, setRewards, dependenciesLoaded, setDependenciesLoaded, accountAndFiltersLoaded, setAccountAndFiltersLoaded, refreshRanking, setRefreshRanking, setFaithPrice, setPackPrice);

  const { handleLogout } = useLogin(payload, setPayload, address, setAddress, wallet, setWallet, readyState, sendMessage, setAccountAndFiltersLoaded, setAccountData);

  const websocketData = useSelector((state) => state.websocketData);
  const onlinePlayers = useSelector((state) => state.onlinePlayers);
  const playerCollection = useSelector((state) => state.playerCollection.collection);
  const combatRoundsData = useSelector((state) => state.combatRounds);
  const vortexEffectContainer = useRef()
  const pixiDustContainer = useRef()
  const cardsContainerRef = useRef(null)
  const cardPackContainerRef = useRef()
  const floatingAnimationRef = useRef(null);
  const godRef1 = useRef([])
  const godRef2 = useRef(null)
  const critterRef = useRef([])
  const slotRef = useRef()
  const emitterRef = useRef(null);
  const godzillaTimeout = useRef();

  const handleUsernameSubmit = async (name) => {
    // Handle the submitted username (e.g., save it to the database or state)
    setIsLoading(true)
    try {
      await axios.post(`${dbApiUrl}/account/name`, { name, payload })
      setAccountData({ ...accountData, name })
    } catch(e) {
      console.error(e)
    }
    setIsLoading(false)
  };
  
  const navigate = useNavigate();

  const handleLogin = async () => {
    setOpenWalletSelector(true);
  }

  const backToMenu = () => {
    setTimeout(() => {
      resetMenu()
      setRoute('/battle')
    }, 100)
  }

  const findNewCards = async () => {
    let collection = await fetchCollection(address, wallet);
    while (collection.length == playerCollection.length) {
      await wait(3000)
      collection = await fetchCollection(address, wallet)
    }
    // console.log("collection Size", collection.length)
    // console.log("playerCollection Size", playerCollection.length)
    dispatch(setCollection(collection));

    const newCards = [];
    for (let i = 0; i < collection.length; i++) {
      const card = collection[i];
      if (playerCollection.filter(el => el.tokenId == card.tokenId).length == 0) newCards.push(card)
    }
    return newCards;
  }
 
  const acknowledgeAction = () => dispatch(setRequestAction({ requestAction: [] }));
  
  const handleMessage = (type, colorIndex) => {
    const msg = JSON.stringify({ type: type, data: { colorIndex: colorIndex } });
    sendMessage(msg);
    if (type == "pickCiv") setShowCivs(false);
  };

  const resetMenu = () => {
    setRefreshAccountData(true)
    setRefreshBalances(1)
    setIsSearching(false)
    setColors(null)
    setPlayers(null)
    setShowCivs(null)
    setIsReplay(false)
    setGameStart(false)
    setWaitingForOpponent(false)
    setFirstToAct(null)
    setGameOver(false)
    dispatch(resetData())
  }

  const broadcastReferrerCode = (referrerCode) => {
    console.log("*** broadcastReferrerCode ***")
    return axios.post(`${dbApiUrl}/referrer`, { payload, referralCode: referrerCode }).then((res) => {
      Cookies.remove('referrerCode')
      console.log(res)
    }).catch(console.error)
  }

  const handleMatchmakingAlertJoin = () => {
    handleMessage("play")
    setIsSearching(true);
  }

  useEffect(() => {
    if (accountData && payload && Cookies.get('referrerCode') && !referrer) broadcastReferrerCode(Cookies.get('referrerCode'))
  }, [accountData, payload])

  useEffect(() => {
    // console.log("canBuyPacks", canBuyPacks)
    if (wallet && canBuyPacks > 0 && canBuyPacks !== parseInt(localStorage.getItem("canBuyPacks"))) {
      localStorage.setItem("canBuyPacks", canBuyPacks)
      setShowCanBuyPacks(canBuyPacks)
    }
  }, [canBuyPacks, wallet])

  useEffect(() => {
    if (packPrice && accountData?.mfaith > packPrice) {
      const packs = Math.floor((accountData.mfaith / 1000) / packPrice)
      setCanBuyPacks(packs)
    }
  }, [accountData, packPrice])

  useEffect(() => {
    let timeout
    if (websocketData?.winner) {
      timeout = setTimeout(() => {
        if (!combatBusy && !isReplay) setGameOver(true)
      }, 3000)
    }

    return () =>  {
      clearTimeout(timeout)
    }
  }, [websocketData?.winner, combatBusy])

  useEffect(() => {
    if (address && !isSearching && onlinePlayers?.onlinePlayers.length > 0 && onlinePlayers?.onlinePlayers.filter(el => el.status == 'searching' && el.address !== address).length > 0) setMatchmakingAlert(true)
  }, [onlinePlayers?.onlinePlayers])

  useEffect(() => {
    if (dependenciesLoaded) {
      const contracts = getContracts()
      const provider = getProvider()
      const network = getNetwork()
      setNetwork(network);
      setProvider(provider);
      setPacksContract(contracts.packsContract)

      setKoinContract(contracts.koinContract)
      setCardsContract(contracts.cardsContract)
      setFaithContract(contracts.faithContract)

      setLevels(getLevels())
      setUnits(getUnits())
      setConfig(getConfig())
      setAbilities(getAbilities())
      setRewards(getRewards())
      setLevelCap(getLevelCap())
      setLore(getLore())
      setRefreshRanking(1)
      setRefreshBalances(1)
      setRefreshPrices(true)
    }
  }, [dependenciesLoaded])

  useEffect(() => {
    if (location.pathname.includes('/game') && !showBoard) setRoute('/')
    const referrerCode = new URLSearchParams(location.search).get("ref");
    if (referrerCode) Cookies.set('referrerCode', referrerCode, { expires: 365 * 10 })
  }, [location])

  useEffect(() => {
    // console.log("accountData", accountData)
    if (accountData && !accountData.name) setNicknameModal(true)
    if (accountData?.referralCode) setReferralCode(accountData.referralCode)
    if (accountData?.referrer) {
      setReferrer(accountData.referrer)
      if (Cookies.get('referrerCode')) Cookies.remove('referrerCode')
    }
  }, [accountData])

  useEffect(() => {
    // console.log("*** BG image useEffect ***", bgImageUrl)
    const body = document.body;

    if (!bgImageUrl) {
      body.style.backgroundImage = ''
    } else if (bgImageUrl == 'purple') {
      body.style.backgroundImage = 'linear-gradient(to bottom, #32004b, #000000)'
      body.style.backgroundRepeat = 'no-repeat';
      body.style.backgroundPosition = 'center center';
      body.style.backgroundSize = 'cover';
      body.style.backgroundAttachment = 'fixed';

    } else {
      // Apply the background image with a semi-transparent overlay
      body.style.backgroundImage = `
        linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), 
        url(${`${cdnUrl}/logos-icons/backgrounds/${bgImageUrl}.webp`})
      `;
      body.style.backgroundRepeat = 'no-repeat';
      body.style.backgroundPosition = 'center center';
      body.style.backgroundSize = 'cover';
      body.style.backgroundAttachment = 'fixed';
    }

    return () => {
      // Cleanup the background image when the component unmounts or changes
      body.style.backgroundImage = '';
    };
  }, [bgImageUrl]);

  function fadeGodzilla() {
    
    if (godzillaAudio.volume > 0) {
      try {
        godzillaAudio.volume -= 0.005;
      } catch(e) {
        godzillaAudio.currentTime = 0
        godzillaAudio.volume = 1;
        godzillaAudio.pause()
        clearTimeout(godzillaTimeout.current)
      }
      godzillaTimeout.current = setTimeout(fadeGodzilla, 25);
    }
  }

  // useEffect(() => {
  //   console.log("faithBalance", faithBalance)
  // }, [faithBalance])

  useEffect(() => {
    if (gameStart) {
      godzillaAudio.volume = 1;
      godzillaAudio.play()
    }

    return () => {
      clearTimeout(godzillaTimeout.current)
      godzillaAudio.pause()
    }
  }, [gameStart])

  useEffect(() => {
    const actions = []
    if (ranking && ranking.length > 0) {
      for (let address in onlinePlayers.users) {
        const _ranking = ranking.find(el => el.address == address)
        if (_ranking) actions.push([`playerData.${address}`, 'set', _ranking, 0])
      }
      dispatch(updatePlayersData(actions));
    }
  }, [ranking])

  useEffect(() => {
    if (websocketData?.requestAction?.includes("pickCiv")) setShowCivs(true);
  }, [websocketData.requestAction]);

  useEffect(() => {
    if (!address && isSearching) resetMenu()
  }, [address])

  // useEffect(() => {
  //   console.log(websocketData)
  // }, [websocketData])

  const processMessage = useCallback((message) => {
    const { type, data } = message;
    // console.log(type, data)

    switch (type) {

      case "cancel":
        // console.log("*** CANCEL CONFIRMATION ***")
        resetMenu();
        break;

      case "login":
        // console.log("*** LOGIN CONFIRMATION ***")
        resetMenu()
        setLoggedIn(true)
        break;

      case "playerState":
        // console.log("playerState", data)
        dispatch(setPlayersInitialState({ ...onlinePlayers, ...data }));
        break;

      case "playerStateUpdate":
        dispatch(updatePlayersData(data));
        break;

      case "wait":
        setWaitingForOpponent(true);
        break;

      case "gameStart":
        setGameStart(true)
        setIsSearching(false)
        setWaitingForOpponent(false);
        setIsReplay(data.isReplay);
        setFirstToAct(data.firstToAct);
        dispatch(setInitialState({ ...data }));
        break;

      case "combatData":
        // console.log("combatData", data.roundActions)
        dispatch(addCombatRound(data.roundActions));
        break;

      case "state": {
        // console.log("state", data);
        const mySeat = data.p1.player === address ? 'p1' : 'p2';
        const opponentSeat = mySeat === 'p1' ? 'p2' : 'p1';
        dispatch(setInitialState({ ...websocketData, ...data, mySeat, opponentSeat, requestAction: [] }));
        break;
      }

      case "update":
        if (!websocketData.isLoaded) {
          wait(1500).then(() => dispatch(updateData(data)));
          break;
        }      
        dispatch(updateData(data));
        break;

      case "gameOver":
        dispatch(updateData(data));
        break;

      case "requestAction":
        dispatch(setRequestAction({ requestAction: data.type }));
        break;

      case 'challengeRequest':
        setChallengeRequest({ from: data.from });
        break;

      default:
        break;
    }
  }, [ranking, address, websocketData]);

  // const lastMessageIndex = useRef(0);
  const [processing, setProcessing] = useState(false);

  useEffect(() => {
    // console.log("messageQueue", messageQueue.length)
    if (!processing && messageQueue.length > 0) {
      setProcessing(true);
      processNextMessage();
    }
  }, [messageQueue, processing]);

  const processNextMessage = () => {
    if (messageQueue.length > 0) {
      // console.log(`Processing message ${messageQueue[0].type}`);
      processMessage(messageQueue[0]);
      // lastMessageIndex.current += 1;
      setMessageQueue(prev => prev.slice(1))

      // Process the next message in the queue
      // setTimeout(processNextMessage, 0); // Schedule next message processing
    } 
    setProcessing(false); // No more messages to process
    
  };

  useEffect(() => {
    let timeout;

    if (!websocketData.isReplay && websocketData.round < 2 && typeof websocketData?.p1?.colorIndex === 'number' && typeof websocketData?.p2?.colorIndex === 'number' && !showBoard) {
      timeout = setTimeout(() => { 
        setShowBoard(true);
        setRoute("/game")
        fadeGodzilla()
        const civs = ["greece", "egypt", "norse"];
        const colorIndex = websocketData[websocketData.mySeat].colorIndex;
        setBgImageUrl(civs[colorIndex]);
      }, 3000);
    }
    if (websocketData.round < 2 && !players && websocketData?.p1?.player && websocketData?.p2?.player) setPlayers({ p1: websocketData.p1.player, p2: websocketData.p2.player });

    if (websocketData.round < 2 && !colors?.p1 && typeof websocketData?.p1?.colorIndex == 'number') {
      setColors(prevColors => ({ ...prevColors, p1: websocketData.p1.colorIndex }));
    }
    if (websocketData.round < 2 && !colors?.p2 && typeof websocketData?.p2?.colorIndex == 'number') {
      setColors(prevColors => ({ ...prevColors, p2: websocketData.p2.colorIndex }));
    }
    return () => {
      clearTimeout(timeout);
    }
  }, [websocketData]);

  useEffect(() => {
    if (websocketData.isReplay && !location.pathname.includes("/replay/")) {
      // alert("BINGO")
      const msg = JSON.stringify({ type: 'stopReplay', data: { } });
      sendMessage(msg);
      resetMenu()
    } 

    // if (!address && (location.pathname == '/collection' || location.pathname == '/openpacks')) {
    //   handleLogin()
    // }

  }, [location.pathname])


  // It's important to have this intermediate routing through "setRoute" to give pixijs enough time to 
  // unmount it's animations during page transitions. If directly nagivating using native react router 
  // it will crash

  useEffect(() => {
    if (route == '/battle' || route == '/collection') setBgImageUrl(null)

    if (showBoard && !(route == "/game" || route.includes("/replay/"))) {

      if (manaParticleContainerRef.current) {
        manaParticleContainerRef.current.destroy()
      }

      if (manaParticleContainerRef2.current) {
        manaParticleContainerRef2.current.destroy()
      }

      if (effectContainerRef.current) {
        effectContainerRef.current.destroy()
      }

      if (godRef1.current && godRef1.current.length > 0) godRef1.current.forEach(el => {
        if (el) el.destroy()
      })
      if (godRef2.current) godRef2.current.destroy()
      if (critterRef.current && critterRef.current.length > 0) critterRef.current.forEach(el => {
        if (el) el.destroy()
      })
      if (slotRef.current) slotRef.current.destroy()

      setShowBoard(false);
      resetMenu()
      dispatch(resetData());
      dispatch(resetCombatData());
      setRefreshRanking(3000)

    } 
    else if (location.pathname == "/openpacks" && route !== "/openpacks") {

      if (vortexEffectContainer.current) vortexEffectContainer.current.destroy()
      if (pixiDustContainer.current) pixiDustContainer.current.destroy()
      if (cardPackContainerRef.current) cardPackContainerRef.current.destroy()
      if (cardsContainerRef.current) cardsContainerRef.current.destroy()
      if (floatingAnimationRef.current) floatingAnimationRef.current.kill()
    }
    navigate(route)
  }, [route])

  return (

    
    <LoadingOverlay isLoading={isLoading} >
    <div className={`App`}>
      {/*<Maintenance />*/}
      <WarmupSeasonDisclaimer />

      <PackAlert wallet={wallet} showCanBuyPacks={showCanBuyPacks} setShowCanBuyPacks={setShowCanBuyPacks}/>

      <Chat 
        accountData={accountData} 
        address={address} 
        wallet={wallet}
        onlinePlayers={onlinePlayers}
      />
      {
        !showBoard &&
        <MatchmakingAlert open={matchmakingAlert} onClose={() => setMatchmakingAlert(false)} onJoin={handleMatchmakingAlertJoin}/>
      }

      {
        <PlayerModal  player={playerModal} setPlayer={setPlayerModal} sendMessage={sendMessage}/>
      }
      {
        <ChallengeSnackbar challengeRequest={challengeRequest} sendMessage={sendMessage} location={location} setRoute={setRoute}/>
      }
      {
        !isSmallScreen && !showBoard && location.pathname !== '/' &&
        <NavBar setRoute={setRoute} address={address} login={handleLogin} logout={handleLogout} wallet={wallet} accountData={accountData}/>
      }
      {
        isSmallScreen && !showBoard && location.pathname !== '/' &&
        <MobileNavBar setRoute={setRoute} address={address} login={handleLogin} logout={handleLogout} wallet={wallet} accountData={accountData}/>
      }
      <WalletSelector 
        open={openWalletSelector} 
        onClose={() => setOpenWalletSelector(false)} 
        setWallet={setWallet} 
        setPayload={setPayload} 
        setAddress={setAddress} 
        wallet={wallet}
      />
      {/*<Dialog isGameOver={isGameOver} winner={websocketData.winner} websocketData={websocketData} backToMenu={backToMenu}/>*/}
      <Matchend 
        dependenciesLoaded={dependenciesLoaded} 
        isGameOver={isGameOver} 
        winner={websocketData.winner} 
        websocketData={websocketData} 
        backToMenu={backToMenu}
        effectContainerRef={effectContainerRef}
      />
      { 
        location.pathname === "/" && !showBoard && nodeEnv !== "development2" &&
        <YouTubeBackground videoId="Ow3D1bLhCKY" />
      }
      
      <Routes>
        <Route path="/" element={
          <Home 
          />
        }></Route>

        <Route path="/wallet" element={
          <InternalWallet 
            accountData={accountData}
            address={address}
            wallet={wallet}
            payload={payload}
            setIsLoading={setIsLoading}
            setRefreshAccountData={setRefreshAccountData}
            handleLogin={handleLogin}
          />
        }></Route>

        <Route path="/referral" element={
          <Referral
            referralCode={referralCode} 
            address={address}
          />
        }></Route>
        <Route path="/game" element={
          <div className="canvas-container">
            <Game 
              sendMessage={sendMessage} 
              websocketData={websocketData} 
              combatRoundsData={combatRoundsData} 
              address={address} 
              acknowledgeAction={acknowledgeAction} 
              firstToAct={firstToAct}
              isReplay={isReplay}
              godRef1={godRef1}
              godRef2={godRef2}
              critterRef={critterRef}
              slotRef={slotRef}
              setGameOver={setGameOver}
              isGameOver={isGameOver}
              manaParticleContainerRef={manaParticleContainerRef}
              manaParticleContainerRef2={manaParticleContainerRef2}
              combatBusy={combatBusy}
              setCombatBusy={setCombatBusy}
            />
          </div>
        }></Route>
        <Route path="/unsubscribe" element={
          <div className="canvas-container">
            <Unsubscribe 
            />
          </div>
        } />
        <Route path="/replay/:replayId" className="canvas-container" element={
          <div className="canvas-container">
            <Replay 
              handleMessage={handleMessage} 
              waitingForOpponent={waitingForOpponent} 
              address={address} 
              showCivs={showCivs} 
              gameStart={gameStart}
              websocketData={websocketData}
              players={players}
              colors={colors}
              onlinePlayers={onlinePlayers}
              history={history}
              sendMessage={sendMessage} 
              firstToAct={firstToAct}
              acknowledgeAction={acknowledgeAction}
              combatRoundsData={combatRoundsData}
              showBoard={showBoard}
              godRef1={godRef1}
              godRef2={godRef2}
              critterRef={critterRef}
              slotRef={slotRef}
              readyState={readyState}
            />
          </div>
        } />
        <Route path="/collection" element={
          <Collection 
            address={address}
            setIsLoading={setIsLoading}
            isLoading={isLoading}
            playerCollection={playerCollection}
            units={units}
            wallet={wallet}
            payload={payload}
            setRefreshCollection={setRefreshCollection}
          />
        } />
        <Route path="/card-details/:cardId/:foil" element={
          <>
            {
              config &&
              <CardDetails
                units={units}
                levelSteps={levels}
                setBgImageUrl={setBgImageUrl}
                abilities={abilities}
                playerCollection={playerCollection}
                address={address}
                setRefreshCollection={setRefreshCollection}
                levelCap={levelCap}
                lore={lore}
                wallet={wallet}
                setIsLoading={setIsLoading}
                config={config}
                findNewCards={findNewCards}
              />
            }
          </>
        } />
        <Route path="/openpacks" className="canvas-container" element={
          <div className="canvas-container">
            {
              config &&
              <OpenPacks
                address={address} 
                vortexEffectContainer={vortexEffectContainer} 
                cardPackContainerRef={cardPackContainerRef} 
                // packsBalance={packsBalance} 
                floatingAnimationRef={floatingAnimationRef} 
                pixiDustContainer={pixiDustContainer}
                setIsLoading={setIsLoading}
                cardsContainerRef={cardsContainerRef}
                emitterRef={emitterRef}
                wallet={wallet}
                findNewCards={findNewCards}
                config={config}
                setRefreshCollection={setRefreshCollection}
              />
            }
          </div>
        } />
        <Route path="/test" className="canvas-container" element={
          <div className="canvas-container">
            {
              config &&
              <Test
                setShowBoard={setShowBoard}
              />
            }
          </div>
        } />
        <Route path="/packs" className="canvas-container" element={
          <Packs 
            setIsLoading={setIsLoading}
            address={address}
            wallet={wallet}
            provider={provider}
            packsContract={packsContract}
            faithBalance={faithBalance}
            faithContract={faithContract}
            faithPrice={faithPrice}
            setRefreshBalances={setRefreshBalances}
          />
        } />
        <Route path="/shop" className="canvas-container" element={
          <Shop 
            address={address}
            wallet={wallet}
            accountData={accountData}
            setIsLoading={setIsLoading}
            handleLogin={handleLogin}
          />
        } />
        <Route path="/battle" element={
          <div>
            {
              config &&
              <Battle
                setRoute={setRoute}
                setShowBoard={setShowBoard} 
                ranking={ranking}
                history={history}
                sendMessage={sendMessage}
                isSearching={isSearching}
                setIsSearching={setIsSearching}
                gameStart={gameStart}
                websocketData={websocketData}
                waitingForOpponent={waitingForOpponent}
                showCivs={showCivs}
                isReplay={isReplay}
                colors={colors}
                handleUsernameSubmit={handleUsernameSubmit}
                nicknameModal={nicknameModal}
                setNicknameModal={setNicknameModal}
                accountData={accountData}
                isLoggedIn={isLoggedIn}
                handleLogin={handleLogin}
                address={address}
              />
            }
          </div>
        } />
      </Routes>
      {location.pathname !== '/' && <Footer />}
    </div>
    </LoadingOverlay>

  );
}

export default App;
