import axios from "axios";
import { addLevels, addFilters, addColors, addTypeAndColor } from './collection';
import { dbApiUrl, nodeEnv } from "../constants";
import { cardsContract } from "../contracts/koinos"

export const fetchDeckFilters = (address) => {
  return axios.get(`${dbApiUrl}/deckfilter`, { params: { address }}).then(res => res.data.filters);
}

export const fetchAccountData = (address) => {
  return axios.get(`${dbApiUrl}/account`, { params: { address }}).then(res => res.data.account);
}

export const fetchGameHistory = (address) => {
  return axios.get(`${dbApiUrl}/games`, { params: { address }}).then(res => res.data.history);
}

export const fetchRanking = () => {
  return axios.get(`${dbApiUrl}/ranking`).then(res => res.data.ranking);
}

export const fetchCollection = async (address, wallet) => {
  // console.log("address", address)
  // console.log("wallet", wallet)
  const deckFilters = await fetchDeckFilters(address);
  if (nodeEnv === 'development2') {
    return fetchCardsFromKoinosChain(address).then(res => {
      const addedLevels = addLevels(res, address);
      const addedFilters = addFilters(addedLevels, deckFilters);
      const addedColors = addColors(addedFilters);
      return addedColors;
    });
  } else {
    return axios.get(`${dbApiUrl}/collection`, { params: { address, network: wallet } })
      .then((res) => {
        // console.log("collection", res.data.collection)
        const addedCardPower = res.data.collection.map(el => ({ ...el, cardPower: el.burnt+1 })); // in the case of linea burnt == cardPower
        const addedLevels = addLevels(addedCardPower, address);
        const addedTypesAndColors = addTypeAndColor(addedLevels);
        const addedFilters = addFilters(addedTypesAndColors, deckFilters);
        // console.log("addedFilters", addedFilters)
        return addedFilters;
      });
  }
}

export const getTokensAndAttributesByOwner = (lastCardId, address) => {
  return cardsContract.functions.get_tokens_and_attributes_by_owner({
    owner: address,
    start: lastCardId, // usa "" para la primer pagina
    limit: 100
  }).then(res => res.result)
}

export const fetchCardsFromKoinosChain = async (address) => {
  let isFinished = false;
  let cards = [];
  let lastCardId;
  
  while (!isFinished) {
    const result = await getTokensAndAttributesByOwner(lastCardId, address);
    if (!result) {
      isFinished = true;
      continue;
    }
    const { token_ids, attributes } = result;
    
    // Create the burnt attribute and set it to 0 if it is not present
    const combinedDataCard = attributes.map((el, index) => {
      return { ...attributes[index], token_id: token_ids[index], burnt: attributes[index].burnt > 0 ? (attributes[index].burnt - 1) : 0 };
    });

    lastCardId  = token_ids[token_ids.length - 1];
    cards.push(...combinedDataCard);
    if (attributes.length < 100) isFinished = true;
  }

  // Add card power attribute
  cards = cards.map(el => ({ ...el, cardPower: el.burnt + 1 }))

  // Sort the cards by burnt in descending order
  cards.sort((a, b) => b.burnt - a.burnt);

  console.log("cards", cards);
  return cards;
}