import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setCollection } from '../../features/playerCollection/playerCollectionSlice';
import { fetchCollection, fetchRanking, fetchAccountData, fetchGameHistory } from '../../utils/fetchData';
import { gameDataLoader, getRewards } from '../../constants';
import { wait } from "../../utils";
import { getFaithUsdPrice, getPackFaithPrice } from "../../utils/fetchPrices";
import { getFaithBalance } from "../../utils/fetchBalances";

export const useGameData = (
  address, 
  wallet, 
  setRanking, 
  rewards, 
  setRewards, 
  dependenciesLoaded,
  setDependenciesLoaded, 
  accountAndFiltersLoaded, 
  setAccountAndFiltersLoaded,
  refreshRanking,
  setRefreshRanking,
  setFaithPrice,
  setPackPrice
) => {

  const dispatch = useDispatch();
  const [accountData, setAccountData] = useState();
  const [history, setHistory] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [refreshCollection, setRefreshCollection] = useState(false);
  const [refreshAccountData, setRefreshAccountData] = useState(false);
  const [refreshPrices, setRefreshPrices] = useState(false);
  const [faithBalance, setFaithBalance] = useState(false);
  const [refreshBalances, setRefreshBalances] = useState(false);

  useEffect(() => {
    if (refreshBalances > 0) {
      // console.log(`** refreshBalances **`)
      wait(refreshBalances).then(() => {
        setRefreshBalances(null)
        getFaithBalance(address).then(res => {
          setFaithBalance(res)
        })
      })
    }
  }, [refreshBalances])

  useEffect(() => {
    if (refreshPrices) {
      getFaithUsdPrice().then(res => {
        // console.log("res", res)
        setFaithPrice(res)
        
        getPackFaithPrice().then(res => {
          setPackPrice(res)
          setRefreshPrices(false)
        }) 
      })
    }
  }, [refreshPrices])

  useEffect(() => {
    const fetchDependencies = async () => {
      await gameDataLoader()
      setDependenciesLoaded(true);
    };
    fetchDependencies();
  }, []);

  // useEffect(() => {
  //   if (rewards) refreshRanking(dbApiUrl, setRanking, rewards);
  // }, [rewards]);

  useEffect(() => {
    if (refreshRanking > 0) {
      // console.log("*** refreshRanking ***")
      wait(refreshRanking).then(() => {
        setRefreshRanking(null)
        const rewards = getRewards()
        // console.log("rewards", rewards)
        fetchRanking().then(res => setRanking(res.map((el, index) => ({ ...el, reward: rewards[index + 1] }))))
      })
    }
  }, [refreshRanking])

  useEffect(() => {
    if (address && !accountAndFiltersLoaded || refreshAccountData) {
      setRefreshAccountData(false)
      const promise1 = fetchAccountData(address,);
      const promise2 = fetchGameHistory(address);
      Promise.all([promise1, promise2]).then((res) => {
        setAccountAndFiltersLoaded(true);
        // console.log("accountData", res[0])
        setAccountData(res[0]);
        setHistory(res[1]);
      });
    }
  }, [address, refreshAccountData]);

  useEffect(() => {
    if (address && dependenciesLoaded && accountAndFiltersLoaded) {
      setIsLoading(true);
      setRefreshCollection(false);
      fetchCollection(address, wallet).then((res) => {
        console.log("collection", res)
        dispatch(setCollection(res));
        setIsLoading(false);
      });
    }
  }, [address, wallet, dependenciesLoaded, accountAndFiltersLoaded]);

  useEffect(() => {
    // console.log("*** refreshCollection ***")
    if (refreshCollection > 0) {
      setIsLoading(true);
      wait(refreshCollection).then(() => {
        fetchCollection(address, wallet).then((res) => {
          console.log(`refresh new collection size ${res.length}`)
          dispatch(setCollection(res));
          setIsLoading(false);
          setRefreshCollection(null);
        });
      });
    }
  }, [refreshCollection]);

  return { 
    accountData, 
    history, 
    isLoading, 
    refreshCollection, 
    setRefreshCollection, 
    setIsLoading,
    setAccountData,
    setRefreshAccountData,
    setRefreshPrices,
    faithBalance,
    setRefreshBalances
  };
};
