import { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid2'; // Grid version 2
import TopBanner from '../components/cardDetails/TopBanner.jsx';
import StatsTable from "../components/cardDetails/StatsTable.jsx";
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import CardsTable from "../components/cardDetails/CardsTable.jsx";
import LevelCapTable from "../components/cardDetails/LevelCapTable.jsx";
import { useWriteContract, useWaitForTransactionReceipt } from 'wagmi'
import { Transaction } from "koilib";
import * as kondor from "kondor-js";
import { provider, cardsContract } from "../contracts/koinos"
import nftAbi from "../contracts/linea/abis/nft.json";
// import { testnet } from "../contracts/linea/config.json";
import { cdnUrl } from "../constants.js";
import TransferDialog  from "../components/cardDetails/TransferDialog";

// const { nft: nftAddress } = testnet;

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      className="CustomTabPanel"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 0 }}>
          <Typography component={"div"}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};


export default function CardDetails({ findNewCards, config, setIsLoading, wallet, lore, levelCap, units, levelSteps, abilities, playerCollection, address, setRefreshCollection }) {
  const { contracts: { alphaEdition: { linea: { mainnet: { nft: nftContractAddress } } } } } = config;
  const { cardId, foil } = useParams();
  const navigate = useNavigate();
  const [tableData, setTableData] = useState();
  const [imgUrl, setImgUrl] = useState(null);
  const [_foil, setFoil] = useState(foil);
  const [cards, setCards] = useState([]);
  const [tabValue, setTabValue] = useState(0);
  const [isGod, setIsGod] = useState(false);
  const [selected, setSelected] = useState([]);
  const [transferDialog, setTransferDialog] = useState(false);
  // console.log("debugg", useWriteContract())
  const { writeContractAsync, data: hash } = useWriteContract()

  const evmMerge = () => {
    const [targetTokenId, ...tokenIds] = selected;
    const payload = { 
      abi: nftAbi,
      address: nftContractAddress,
      functionName: 'merge',
      args: [
        tokenIds,
        targetTokenId
      ],
    }

    return writeContractAsync(payload)
  }

  const evmTransfer = (to) => {
    const payload = { 
      abi: nftAbi,
      address: nftContractAddress,
      functionName: 'transferFrom',
      args: [
        address,
        to,
        selected
      ],
    }

    return writeContractAsync(payload)
  }

  const { isLoading: isConfirming, isSuccess: isConfirmed } =
    useWaitForTransactionReceipt({
      hash,
    })

  const transferHandler = async (to) => {
    setTransferDialog(false)
    if (wallet == "MetaMask") {
      console.log(`Transfering NFT with tokenId ${selected} to ${to}`)
      evmTransfer(to)
    }
  }

  const combineHandler = async () => {
    console.log("selected", selected)
    if (wallet == 'Kondor') {
      const signer = kondor.getSigner(address);

      const transaction = new Transaction({
        provider,
        signer,
        options: {
          rcLimit: "1500000000",
          // by default the user is the payer of the mana
          // but we can define another one
          // payer: "...",
        },
      });

      const payload = {
        owner: address,
        token_ids: selected.filter((_, index) => index !== 0), // target_id cannot be included in tokens_id
        target: selected[0] // card with the highest "burnt" is the target - selected array is sorted by "burnt" in descending order
      }

      console.log("payload", payload)

      await transaction.pushOperation(cardsContract.functions.fusion, payload);

      await transaction.sign();
      console.log("transaction", transaction)
      const result = await transaction.send(transaction)
      console.log(result)
      setRefreshCollection(10000) // time to wait in ms
    } else if (wallet == 'MetaMask') {
      try {
        const result = await evmMerge()
        console.log(result)
      } catch(e) {
        console.error(e)
      }
    }
  }

  useEffect(() => {
    if (isConfirming) {
      setIsLoading(true)
    }
    if (isConfirmed) {
      findNewCards().then(res => {
        console.log("new cards!", res)
        setIsLoading(false)
      })
    }
  }, [isConfirming, isConfirmed])

  // useEffect(() => {
  //   console.log("cards", cards)
  // }, [cards])

  useEffect(() => {
    if (cards && cardId) {
      const match = units.find(el => el.cardId == cardId)
      console.log("match", match)
      console.log("cardId", cardId)
      setIsGod(match.type == "Summoner")
    }
  }, [cards, cardId])

  useEffect(() => {
    if (foil) setFoil(foil)
  }, [foil]);

  useEffect(() => {
    if (_foil !== foil) {
      navigate(`/card-details/${cardId}/${_foil}`);
    }
  }, [_foil]);

  useEffect(() => {
    if (units && units.length > 0 && cardId) {
      console.log("*** setTableData ***")
      setTableData(units.find(el => el.cardId == cardId));
    }
  }, [cardId, units]);

  useEffect(() => {
    // console.log("playerCollection", playerCollection)
    if (cardId && playerCollection && playerCollection.length > 0) {
      setCards(playerCollection.filter(el => el.cardId == cardId))
    }
  }, [playerCollection])

  useEffect(() => {
    if (tableData) {
      setImgUrl(`${cdnUrl}/cards/webp/v3/${_foil}/${tableData.name.replace(" ", "-")}.webp`);

      // switch (tableData.color) {
      //   case "White":
      //     setBgImageUrl('greece');
      //     break;
      //   case "Yellow":
      //     setBgImageUrl('egypt');
      //     break;
      //   case "Purple":
      //     setBgImageUrl('norse');
      //     break;
      //   default:
      //     setBgImageUrl('');
      //     break;
      // }
    }
  }, [tableData, _foil]);

  return (
    <>
      <TransferDialog 
        open={transferDialog}
        onClose={() => setTransferDialog(false)}
        onConfirm={transferHandler}
      />
      <TopBanner 
        tableData={tableData} 
        foil={_foil}
        setFoil={setFoil}
        tabValue={tabValue}
        setTabValue={setTabValue}
      />
      <Box 
        className='cardDetails-box'
        sx={{ 
          color: 'white', 
          overflowX: 'hidden', 
          paddingLeft: '0px', 
          paddingRight: '0px',
          fontWeight: 'bold', // Make the text bold
        }}
        >
        <Grid container spacing={0} sx={{ textAlign: 'center', marginTop: '350px' }} offset={{md :2 }}>
          <Grid offset={{ md: 1 }} size={{ xs: 12, md: 4 }} sx={{ padding: '20px' }}>
            {imgUrl && (
              <Box
                component="img"
                src={imgUrl}
                alt={tableData?.name}
                sx={{
                  width: '100%',
                  height: 'auto',
                  maxWidth: '300px', // Set the max width for the image
                  maxHeight: '450px', // Set the max height for the image
                  objectFit: 'contain',
                }}
              />
            )}
          </Grid>
          <Grid size={{ xs: 12, md: 5 }} sx={{ padding: '20px' }}>
            {tableData && (
              <Box className={"bodyBox"} sx={{ width: '100%', padding: '0px' }}>
                <CustomTabPanel value={tabValue} index={0}>
                  <CardsTable
                    wallet={wallet}
                    cards={cards}
                    address={address}
                    foil={_foil}
                    setRefreshCollection={setRefreshCollection}
                    combineHandler={combineHandler}
                    setTransferDialog={setTransferDialog}
                    selected={selected}
                    setSelected={setSelected}
                  />
                </CustomTabPanel>
                <CustomTabPanel value={tabValue} index={1}>
                {
                  !isGod &&
                  <StatsTable 
                    tableData={tableData} 
                    levelSteps={levelSteps}
                    foil={_foil}
                    abilities={abilities}
                  />
                }
                {
                  isGod && tableData &&
                  <LevelCapTable
                    levelCap={levelCap}
                    rarityIndex={tableData.rarity - 1}
                    levelSteps={levelSteps}
                    foil={foil}
                  />
                }
                </CustomTabPanel>
                <CustomTabPanel value={tabValue} index={2} >
                  {lore[cardId - 1]}
                </CustomTabPanel>
              </Box>
            )}
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
