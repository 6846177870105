
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store } from './app/store';
import App from './App.jsx';
import { ThemeProvider } from '@mui/material/styles';
import Theme from "./components/Theme"

import { Web3ModalProvider } from './components/WalletConnect';
import './index.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <BrowserRouter>
      
        <ThemeProvider theme={Theme}>
          <Web3ModalProvider>
            <App />
          </Web3ModalProvider>
        </ThemeProvider>
      
    </BrowserRouter>
  </Provider>
);
