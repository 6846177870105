import {  useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Game from "../pages/Game.jsx"

function Replay({ 
    firstToAct, 
    acknowledgeAction, 
    combatRoundsData, 
    address, 
    websocketData, 
    sendMessage,
    godRef1,
    godRef2,
    critterRef,
    slotRef,
    readyState
  }) {

  const { replayId } = useParams(); // This retrieves the replayId from the URL
  const [combatBusy, setCombatBusy] = useState(false)

  useEffect(() => {
    if (replayId && address && readyState == 1) {
      console.log("Replaying game with ID:", replayId);
      const msg = JSON.stringify({ type: 'replay', data: { gameId: replayId } })
      setTimeout(() => { sendMessage(msg) }, 1000)
    }
  }, [replayId, address, readyState]);

  if (!address) return (<h1>Login is required for replays</h1>)

  return (
    <Game 
      className="gameBoard"
      sendMessage={sendMessage} 
      websocketData={websocketData} 
      combatRoundsData={combatRoundsData} 
      address={address} 
      acknowledgeAction={acknowledgeAction} 
      firstToAct={firstToAct}
      isReplay={true}
      godRef1={godRef1}
      godRef2={godRef2}
      critterRef={critterRef}
      slotRef={slotRef}
      combatBusy={combatBusy}
      setCombatBusy={setCombatBusy}
    />
  )    
}

export default Replay;
