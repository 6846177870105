import { useState, useEffect } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, Checkbox, FormControlLabel, Typography, Table, TableBody, TableCell, TableRow, TableContainer, Paper } from '@mui/material';

const WarmupSeasonDisclaimer = () => {
  const [open, setOpen] = useState(false);
  const [acknowledged, setAcknowledged] = useState(false);

  useEffect(() => {
    // Check local storage if the disclaimer has been acknowledged
    const hasAcknowledged = localStorage.getItem('warmupDisclaimerAcknowledged');
    if (!hasAcknowledged) {
      setOpen(true);
    }
  }, []);

  const handleAcknowledgeChange = (event) => {
    setAcknowledged(event.target.checked);
  };

  const handleAcknowledge = () => {
    if (acknowledged) {
      localStorage.setItem('warmupDisclaimerAcknowledged', 'true');
      setOpen(false);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={() => null}
      aria-labelledby="warmup-season-disclaimer"
      disableEscapeKeyDown
      sx={{
        '& .MuiDialog-paper': {
          border: '2px solid #1976d2', // Change the color and width as needed
          borderRadius: '8px', // Optional: to round the corners
        },
      }}
    >
      <DialogTitle id="warmup-season-disclaimer">
        <Typography style={{ fontFamily: 'Germania One', fontSize: '24px', textAlign: 'center' }}>
          Official Season #2 Rules
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Typography style={{ fontFamily: 'Poppins', marginBottom: '16px', color: '#937341' }}>
          Please acknowledge the following rules before participating in the warmup season:
        </Typography>
        <TableContainer component={Paper}>
          <Table aria-label="rules table">
            <TableBody>
              <TableRow>
                <TableCell style={{ fontFamily: 'Poppins', fontWeight: 'bold' }}>1.</TableCell>
                <TableCell style={{ fontFamily: 'Poppins' }}>
                  The usage of VPNs or proxies is forbidden.
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ fontFamily: 'Poppins', fontWeight: 'bold' }}>2.</TableCell>
                <TableCell style={{ fontFamily: 'Poppins' }}>
                  It is forbidden to purposefully lose to yourself or friends to exploit ranking or play-to-earn mechanics.
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ fontFamily: 'Poppins', fontWeight: 'bold' }}>3.</TableCell>
                <TableCell style={{ fontFamily: 'Poppins' }}>
                  You are not allowed to play against yourself with multiple accounts.
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ fontFamily: 'Poppins', fontWeight: 'bold' }}>4.</TableCell>
                <TableCell style={{ fontFamily: 'Poppins' }}>
                  Players caught cheating will be permanently banned, and their assets (FAITH tokens and NFTs) will become invalid.
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <FormControlLabel
          control={
            <Checkbox
              checked={acknowledged}
              onChange={handleAcknowledgeChange}
              name="acknowledge"
            />
          }
          label="I have read and acknowledged the rules."
          style={{ fontFamily: 'Poppins', marginTop: '16px', color: '#937341' }}
        />
      </DialogContent>
      <DialogActions>
        <Button 
          onClick={handleAcknowledge} 
          disabled={!acknowledged} 
          variant="contained" 
          color="primary"
          style={{ fontFamily: 'Poppins' }}
        >
          Acknowledge
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default WarmupSeasonDisclaimer;
